@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  }
}

/*추가*/
.border-gray-60{
  --tw-border-opacity: 1;
  border-color: rgb(60 60 60 / var(--tw-border-opacity));
}

.px-1\.5{
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
